import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthenService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    public error: any;
    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/signin/admin`, { username, password })
            .pipe(map((response: any) => {
                if (response.status === true) {
                    localStorage.setItem('currentUser', JSON.stringify(response));
                    localStorage.setItem('UserID', response.data.uid);
                    this.currentUserSubject.next(response);
                    this.getRoleAdmin(response.data.uid, response.token.access_token);
                    return response;
                } else {
                    return response;
                }

            }));
    }

    sendMailForgotPassword(body: any) {
        return this.http.post<any>(`${environment.apiUrl}/sendmail/reset/password`, body);
    }

    changePassword(body: any, uid: string) {
        return this.http.post<any>(`${environment.apiUrl}/reset/${uid}/password`, body);
    }

    signup(body: any) {
        return this.http.post<any>(`${environment.apiUrl}/create/user`, body);
    }

    getRoleAdminStartLogin(uid: string, token: string) {
        return this.http.get(`${environment.apiUrl}/get/admin/${uid}`, {
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: 'Bearer ' + token
            }
          });
      }

    getRoleAdmin(uid: string, token: string) {
        this.getRoleAdminStartLogin(uid, token)
          .pipe(first())
          .subscribe(
            (response: any) => {
              if (response.status === true) {
                // if (response.data.role !== null) {
                  if (response.data.role_name.String == '') {
                    localStorage.setItem('role', response.data.role);
                  } else {
                    localStorage.setItem('role', response.data.role_name.String);
                  }
                // }
              } else {
                this.error = response.message;
              }
            },
            error => {
              this.error = error;
            });
      }

    logout() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('UserID');
        localStorage.removeItem('role');
        this.currentUserSubject.next(null);
    }
}
