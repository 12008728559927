export const AuthURL = {
    Dashboard: 'dashboard',
    Setting: 'setting',
    Profile: 'profile',
    //
    ClassificarionTypeList: 'classificationtype-list',
    ClassificarionTypeCreate: 'classificationtype-create',
    ClassificarionTypeEdit: 'classificationtype-edit',

    OrganizationTypeList: 'organizationtype-list',
    OrganizationTypeCreate: 'organizationtype-create',
    OrganizationTypeEdit: 'organizationtype-edit',

    RoleList: 'role-list',
    RoleCreate: 'role-create',
    RoleEdit: 'role-edit',

    SouvenirList: 'souvenir-list',
    SouvenirCreate: 'souvenir-create',
    SouvenirEdit: 'souvenir-edit/:id',

    SubProjectCreate: 'subproject-create',
    SubProjectEdit: 'subproject-edit',
    SubProjectView: 'subproject-view',
    
    UserList: 'user-list',
    UserEdit: 'user-edit',
    UserRycycle: 'user-recycle',
};
