import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppURL } from '../../../app.url';
import { first } from 'rxjs/operators';
import { AuthenService } from '../../../components/_services/authen.service';
import { ProfileAdminService } from '../../../authentication/components/profile/_services/profile-admin.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-auth-navber',
  templateUrl: './auth-navber.component.html',
  styleUrls: ['./auth-navber.component.css']
})
export class AuthNavberComponent implements OnInit {

  displayName: string;
  error: string;
  constructor(
    private authenticationService: AuthenService,
    private toastr: ToastrService,
    private profileService: ProfileAdminService
  ) {
    this.profileService.list$.subscribe(name => (this.displayName = name));
  }

  AppURL = AppURL;

  ngOnInit() {
    this.getUserProfile();
  }


  getUserProfile() {
    this.profileService.getProfileAdmin()
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.displayName =  response.data.username;
          } else {
            this.error = response.message;
            if (this.error === 'Invalid authorization token.') {
              
              this.authenticationService.logout();
              location.href = '/login';
            }
          }
        },
        error => {
          this.error = error;
        });
  }




  logout() {
    this.toastr.success('Logout Successfully', 'Notice!');
    this.authenticationService.logout();
    location.href = '/login';
  }

  toggleSidebar() {
    // layout-expanded
    var element = document.getElementById("html");
    element.classList.add("layout-transitioning");
    element.classList.add("layout-expanded");
  }
}
