import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppURL } from './app.url';
import { AuthGuard } from './components/_helpers/auth.guard';
import { LogoutGuard } from './components/_helpers/logout.guard';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ChangePasswordComponent } from './components/forgot-password/change-password/change-password.component';
import { SendMailComponent } from './components/forgot-password/send-mail/send-mail.component';

const routes: Routes = [
  { path: '', redirectTo: AppURL.Login, pathMatch: 'full' },
  { path: AppURL.Login, component: LoginComponent, canActivate: [LogoutGuard] },
  { path: AppURL.ForGotPassWord, component: SendMailComponent, canActivate: [LogoutGuard] },
  { path: AppURL.ChangePassWord + '/:uid', component: ChangePasswordComponent, canActivate: [LogoutGuard] },
  // { path: AppURL.Register, component: RegisterComponent, canActivate: [LogoutGuard] },
  { path: AppURL.Authen, loadChildren: './authentication/authentication.module#AuthenticationModule', canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
