import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppURL } from '../../../app.url';
import { AuthURL } from '../../../authentication/authentication.url';

import { ProfileAdminService } from '../../../authentication/components/profile/_services/profile-admin.service';
import { AdminService } from './../../../authentication/components/setting/admin/_services/admin.service';

import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenService } from '../../../components/_services/authen.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-auth-sidebar',
  templateUrl: './auth-sidebar.component.html',
  styleUrls: ['./auth-sidebar.component.css']
})
export class AuthSidebarComponent implements OnInit {

  displayName: string;
  error: string;
  url: string;
  role = localStorage.getItem('role');
  constructor(
    private router: Router,
    private adminService: AdminService,
    private authenticationService: AuthenService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private profileService: ProfileAdminService
  ) {
    this.url = environment.apiUrl;
    this.profileService.list$.subscribe(name => (this.displayName = name));
    this.profileService.avatar$.subscribe(avatar => (this.avatar = avatar));
  }
  // tslint:disable-next-line: member-ordering
  AppURL = AppURL;
  AuthURL = AuthURL;



  ngOnInit() {
    this.getUserProfile();
  }

  toggleSidebar() {
    // layout-expanded
    const element = document.getElementById('html');
    element.classList.remove('layout-expanded');
    const setting = document.getElementById('setting-admin');
    if (setting.classList.contains('show')) {
      setting.classList.remove('show');
    }
  }

  logout() {
    this.toastr.success('Logout Successfully', 'Notice!');
    this.authenticationService.logout();
    location.href = '/login';
  }

  toggleTooltop() {
    const setting = document.getElementById('setting-admin');
    if (setting.classList.contains('show')) {
      setting.classList.remove('show');
    } else {
      setting.classList.remove('hide-tooltip');
      setting.classList.add('show');
    }
  }

  profile: any;
  avatar: string;
  getUserProfile() {
    this.profileService.getProfileAdmin()
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.profile = response.data
            this.avatar = `${this.url}/avatar/${response.data.avatar}`;
            this.displayName = response.data.username;
          } else {
            this.error = response.message;
          }
        },
        error => {
          this.error = error;
        });
  }


  setActive() {
    // admin
    const admin = document.getElementById('admin');
    const textAdmin = document.getElementById('admin-text');
    // setting
    const settingModule = document.getElementById('setting-module');
    const settingText = document.getElementById('setting-text');
    // Organization Type
    const ozType = document.getElementById('ozType');
    const textOztype = document.getElementById('ozType-text');
    // Classification
    const classification = document.getElementById('classificationSide');
    const textClassification = document.getElementById('classification-text');
    // Promise
    const promise = document.getElementById('promise');
    const textPromise = document.getElementById('promise-text');
    // Status
    const status = document.getElementById('status');
    const textStatus = document.getElementById('status-text');
    // Bank
    const bank = document.getElementById('bank');
    const textBank = document.getElementById('bank-text');
    // Branch
    const branch = document.getElementById('branch');
    const textBranch = document.getElementById('branch-text');
    // term Setting 
    const term = document.getElementById('term');
    const textTerm = document.getElementById('term-text');
    // Mobile Setting 
    const mobile = document.getElementById('mobile-setting');
    const textMobile = document.getElementById('mobile-text');
    // User
    const user = document.getElementById('user');
    const textUser = document.getElementById('user-text');
    // Organization
    const oz = document.getElementById('oz');
    const textOz = document.getElementById('oz-text');
    // Project Management
    const project = document.getElementById('projectSide');
    const textProject = document.getElementById('project-text');
    // Souvenir
    const souvenir = document.getElementById('souvenir');
    const textSouvenir = document.getElementById('souvenir-text');
    // Receipt
    const receipt = document.getElementById('receipt');
    const textReceipt = document.getElementById('receipt-text');
    // Donor Care
    const donorCare = document.getElementById('donorCare');
    const textDonorCare = document.getElementById('donorCare-text');

    if (this.route.snapshot.routeConfig.path === 'admin-list/:tag') {
      admin.classList.add('tab-active');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      textAdmin.classList.add('active-text');
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'admin-edit/:id') {
      admin.classList.add('tab-active');
      textAdmin.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'receipt-view') {
      receipt.classList.add('tab-active');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      textReceipt.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'header-edit/:id') {
      receipt.classList.add('tab-active');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      textReceipt.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'header-create') {
      receipt.classList.add('tab-active');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      textReceipt.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'receiver-edit/:id') {
      receipt.classList.add('tab-active');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      textReceipt.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'receiver-create') {
      receipt.classList.add('tab-active');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      textReceipt.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'authorized-edit/:id') {
      receipt.classList.add('tab-active');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      textReceipt.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'authorized-create') {
      receipt.classList.add('tab-active');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      textReceipt.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'address-create') {
      receipt.classList.add('tab-active');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      textReceipt.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'address-edit/:id') {
      receipt.classList.add('tab-active');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      textReceipt.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'role-create') {
      admin.classList.add('tab-active');
      textAdmin.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'role-edit/:id') {
      admin.classList.add('tab-active');
      textAdmin.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organizationtype-list') {
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'organizationtype-create') {
      ozType.classList.add('tab-active');
      textOztype.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organizationtype-edit/:id') {
      ozType.classList.add('tab-active');
      textOztype.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'classificationtype-list') {
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'classificationtype-create') {
      classification.classList.add('tab-active');
      textClassification.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'classificationtype-edit/:id') {
      classification.classList.add('tab-active');
      textClassification.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'promise-list') {
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'promise-create') {
      promise.classList.add('tab-active');
      textPromise.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'promise-edit/:id') {
      promise.classList.add('tab-active');
      textPromise.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'subpromise-create/:id') {
      promise.classList.add('tab-active');
      textPromise.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'subpromise-edit/:id') {
      promise.classList.add('tab-active');
      textPromise.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'status-list') {
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'status-edit/:id') {
      status.classList.add('tab-active');
      textStatus.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'status-create') {
      status.classList.add('tab-active');
      textStatus.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'bank-list') {
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'bank-edit/:id') {
      bank.classList.add('tab-active');
      textBank.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'bank-create') {
      bank.classList.add('tab-active');
      textBank.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'branch-list') {
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'branch-edit/:id') {
      branch.classList.add('tab-active');
      textBranch.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'branch-create') {
      branch.classList.add('tab-active');
      textBranch.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'term-condition') {
      term.classList.add('tab-active');
      textTerm.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'mobile-setting') {
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'ads-create') {
      mobile.classList.add('tab-active');
      textMobile.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'ads-edit/:id') {
      mobile.classList.add('tab-active');
      textMobile.classList.add('active-text');
      settingModule.classList.add('open');
      settingText.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'user-list') {
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'user-create') {
      user.classList.add('tab-active');
      textUser.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'user-recycle') {
      user.classList.add('tab-active');
      textUser.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'user-edit/:id') {
      user.classList.add('tab-active');
      textUser.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organization-list') {
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'organization-create') {
      oz.classList.add('tab-active');
      textOz.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organization-view/:id') {
      oz.classList.add('tab-active');
      textOz.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'project-list') {
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'project-create') {
      project.classList.add('tab-active');
      textProject.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'project-view/:tag/:id') {
      project.classList.add('tab-active');
      textProject.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'project-edit/:id') {
      project.classList.add('tab-active');
      textProject.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'project-member/:id') {
      project.classList.add('tab-active');
      textProject.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'project-classification-edit/:id') {
      project.classList.add('tab-active');
      textProject.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'donates/:id') {
      project.classList.add('tab-active');
      textProject.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'project-classification-create/:projectId') {
      project.classList.add('tab-active');
      textProject.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'subproject-create/:projectId') {
      project.classList.add('tab-active');
      textProject.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'subproject-view/:id') {
      project.classList.add('tab-active');
      textProject.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'member-payment-view/:tag/:id') {
      project.classList.add('tab-active');
      textProject.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'member-payment-create/:id') {
      project.classList.add('tab-active');
      textProject.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'member-payment-edit/:id') {
      project.classList.add('tab-active');
      textProject.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'member-care-create/:pmpId') {
      project.classList.add('tab-active');
      textProject.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'souvenir-list') {
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'souvenir-create') {
      souvenir.classList.add('tab-active');
      textSouvenir.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'souvenir-edit/:id') {
      souvenir.classList.add('tab-active');
      textSouvenir.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'member-payment/:active') {
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'member-care-view/:id') {
      project.classList.add('tab-active');
      textProject.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'donor-care-view/:id') {
      donorCare.classList.add('tab-active');
      textDonorCare.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'payment-view/:id') {
      donorCare.classList.add('tab-active');
      textDonorCare.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'payment-update/:id') {
      donorCare.classList.add('tab-active');
      textDonorCare.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'donor-care-edit/:id') {
      donorCare.classList.add('tab-active');
      textDonorCare.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'donor-member-care/:id') {
      donorCare.classList.add('tab-active');
      textDonorCare.classList.add('active-text');
    }
  }
}
