import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  public token: string;
  public userId: string;

  constructor(private http: HttpClient) {
    const dataUSer = JSON.parse(localStorage.getItem('currentUser'));
    this.token = dataUSer.token.access_token;
    this.userId = dataUSer.data.uid;

  }

  getAdminUsers() {
    return this.http.get(`${environment.apiUrl}/admins`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  getUsersOnly() {
    return this.http.get(`${environment.apiUrl}/users/only`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  getAdminUserByID(uid: string) {
    return this.http.get(`${environment.apiUrl}/get/admin/${uid}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  

  getRoles() {
    return this.http.get(`${environment.apiUrl}/get/roles`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  changeRoleAdmin(uid: string, body: any) {
    return this.http.put(`${environment.apiUrl}/admin/role/update/${uid}`, body, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  updateAdmin(id: string, body: any) {
    return this.http.put(`${environment.apiUrl}/admin/update/${id}`, body, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  removeAdmin(uid: string) {
    return this.http.delete(`${environment.apiUrl}/remove/admin//${uid}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }
}
