import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { __values } from 'tslib';

@Injectable({
  providedIn: 'root'
})
export class ProfileAdminService {

  public token: string;
  public userId: string;
  private list = new BehaviorSubject<string>(null);
  private avatar = new BehaviorSubject<string>(null);
  readonly avatar$ = this.avatar.asObservable();
  readonly list$ = this.list.asObservable();

  constructor(private http: HttpClient) {
    const dataUSer = JSON.parse(localStorage.getItem('currentUser'));
    this.token = dataUSer.token.access_token;
    this.userId = dataUSer.data.uid;
  }

  displayUsername(name: string) {
    this.list.next(name);
  }

  displayAvatar(avatar: string) {
    this.avatar.next(avatar);
  }

  updateProfileAdmin(body: any, uid: string) {
    return this.http.put(`${environment.apiUrl}/user/update/${uid}`, body, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  updateAvatar(body: any, uid: string) {
    return this.http.put(`${environment.apiUrl}/avatar/${uid}`, body, {
      headers: {
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  getProfileAdmin() {
    return this.http.get(`${environment.apiUrl}/user/${this.userId}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  getProvinces() {
    return this.http.get(`${environment.apiUrl}/get/provinces`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  getProvinceByID(id: any) {
    return this.http.get(`${environment.apiUrl}/get/province/${id}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }


  getDistrictsByProvinceId(provinceId: any) {
    return this.http.get(`${environment.apiUrl}/get/districts/${provinceId}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  getDistrictsByID(id: any) {
    return this.http.get(`${environment.apiUrl}/get/district/${id}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  getSubDistrictsByDistrictId(districtId: any) {
    return this.http.get(`${environment.apiUrl}/get/subdistricts/${districtId}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  getSubDistrictsByID(id: any) {
    return this.http.get(`${environment.apiUrl}/get/subdistrict/${id}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  getPhoneNumberByUID(uid: string) {
    return this.http.get(`${environment.apiUrl}/get/v2/phonenumbers/${uid}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  createPhoneNumber(body: any) {
    return this.http.post(`${environment.apiUrl}/create/phonenumber`, body, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  deletePhoneNumber(id: string) {
    return this.http.delete(`${environment.apiUrl}/delete/phonenumbers/${id}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  createAddressOffice(uid: string, body: any) {
    return this.http.post(`${environment.apiUrl}/create/office/address/${uid}`, body, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  createAddressShipping(uid: string, body: any) {
    return this.http.post(`${environment.apiUrl}/create/shipping/address/${uid}`, body, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  updateAddressOffice(id: string, body: any) {
    return this.http.put(`${environment.apiUrl}/put/address/${id}/office`, body, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }

  updateAddressShipping(id: string, body: any) {
    return this.http.put(`${environment.apiUrl}/put/address/${id}/shipping`, body, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }
  checkOldPassword(password: any, uid: string) {
    return this.http.post(`${environment.apiUrl}/check/old/password/${uid}`, { password }, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + this.token
      }
    });
  }
}
